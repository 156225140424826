import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login';

const Routes = () => {
    return (
        <BrowserRouter basename='/login'>
            <Switch>
                <Route exact path='/' component={Login} />
                <Route path='/logout' render={(props) => <Login {...props} logout={true} />} />
                <Route path='*' component={Login} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
