import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/solarbraz_crm'
});

api.interceptors.request.use(async config => {
    const token = JSON.parse(getToken());
    if (token) {
        config.headers.Authorization = `Bearer ${token.gtoken}`;
    }
    return config;
});

export default api;