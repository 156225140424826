import React from "react";
import { GoogleLogin } from "react-google-login";
import { login, logoff, getToken } from "./services/auth";
import api from "./services/api";
import Cookies from 'js-cookie';

import "./Login.css";
import Logo from "./assets/login_img.jfif";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentWillMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    if (params.get('logout')) logoff();
  }

  componentDidMount() {
    const token = () => {
      if (getToken() !== null) {
        const tk = JSON.parse(getToken());
        return {
          code: tk.gtoken
        };
      }
      return null;
    };

    if (token !== null) this.handleSignIn(token());
  }

  handleSignIn = async googleResponse => {
    if (googleResponse) {
      const postData = `authorizationCode=${
        googleResponse.hasOwnProperty("code") ? googleResponse.code : ""
      }&idToken=${
        googleResponse.hasOwnProperty("Zi") ? googleResponse.Zi.id_token : ""
      }`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };

      try {
        const response = await api.post(
          '/service/login/google/token',
          postData,
          { headers: headers }
        );

        const setCookieHeader = response.headers['set-cookie'];
        console.log(setCookieHeader);

        const tokenData = {
          gtoken: googleResponse.code,
          name: response.data.name,
          routePath: response.data.routePath,
          adminPath: response.data.adminPath,
          role: response.data.role
        };

        login(JSON.stringify(tokenData));
        const url = tokenData.routePath;
        if (!!url) window.location.href = url;
      } catch (error) {
        console.log(error);
        this.setState({
          error:
            "Ocorreu um erro ao efetuar o login. Tente novamente ou entre em contato com o administrador."
        });
      }
    }
  };

  handleError = response => {
    console.log(response);
    this.setState({
      error:
        "Ocorreu um erro ao efetuar o login. Tente novamente ou entre em contato com o administrador."
    });
  };

  render() {
    return (
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block">
                    <img
                      className="bg-login-image"
                      src={Logo}
                      alt="Solarbraz"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Bem vindo à Solarbraz!
                        </h1>
                      </div>
                      <div className="row justify-content-center">
                        <GoogleLogin
                          clientId="737257847254-g6skdc5o7a1cp7i74lgkb8sg0qjlirg6.apps.googleusercontent.com"
                          buttonText="Efetue o login usando o Google"
                          onSuccess={this.handleSignIn}
                          onFailure={this.handleError}
                          cookiePolicy={"single_host_origin"}
                          scope={"profile email https://mail.google.com/"}
                          accessType={"offline"}
                          responseType={"code"}
                          theme="dark"
                        />
                      </div>
                      <hr />

                      {!!this.state.error && (
                        <div
                          className="alert alert-danger alert-dismissible fade show small"
                          role="alert"
                        >
                          <strong>Ops!</strong> {this.state.error}.
                          <button
                            type="button"
                            className="close"
                            onClick={() => {
                              this.setState({ error: null });
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
