
//PROD AUTH
export const TOKEN_KEY = "@solarbraz-token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => { localStorage.setItem(TOKEN_KEY, token) };
export const logoff = () => localStorage.removeItem(TOKEN_KEY);
export const getRole = () => (localStorage.getItem(TOKEN_KEY) !== null) ? JSON.parse(localStorage.getItem(TOKEN_KEY)).role : null;

//MOCK AUTH
// export const login = (token) => console.log("Logando");
// export const logoff = (token) => console.log("Logando");
// export const isAuthenticated = () => true;
// export const getToken = () => {
//     return JSON.stringify ({
//         gtoken: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjM0OTRiMWU3ODZjZGFkMDkyZTQyMzc2NmJiZTM3ZjU0ZWQ4N2IyMmQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNzM3MjU3ODQ3MjU0LWc2c2tkYzVvN2ExY3A3aTc0bGdrYjhzZzBxamxpcmc2LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNzM3MjU3ODQ3MjU0LWc2c2tkYzVvN2ExY3A3aTc0bGdrYjhzZzBxamxpcmc2LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAwNzQzMTMzNzgxNjcxNTk1MjQ5IiwiaGQiOiJzb2xhcmJyYXouY29tLmJyIiwiZW1haWwiOiJkZXNlbnZvbHZlZG9yQHNvbGFyYnJhei5jb20uYnIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IkhPNDRiZDFCd091Y3BwSW5LUlhhbUEiLCJuYW1lIjoiRGVzZW52b2x2ZWRvciBTb2xhcmJyYXoiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDUuZ29vZ2xldXNlcmNvbnRlbnQuY29tLy1TdGZCV0UyTHJuQS9BQUFBQUFBQUFBSS9BQUFBQUFBQUFBQS9BQ0hpM3JlbUtsZ3I3ZEM1OEUzdkpzTjZTd3l1TjItZ25BL3M5Ni1jL3Bob3RvLmpwZyIsImdpdmVuX25hbWUiOiJEZXNlbnZvbHZlZG9yIiwiZmFtaWx5X25hbWUiOiJTb2xhcmJyYXoiLCJsb2NhbGUiOiJwdC1CUiIsImlhdCI6MTU2NTIxMjE2NCwiZXhwIjoxNTY1MjE1NzY0LCJqdGkiOiIwNTc4YmYxNTVhNjllNDk4YzAzNmI5N2I3YjhkYjAwMDI0NzExODZkIn0.MF50mTR4CaUeZwzewmaMWcssdDtB-sHR1GbvNYnMGuX9KkIGEuiDqZePGewsgOJCZFjYGNR51JV4g8fNj2lIl_v4bkwVTZGbX0QdvvBYyBOZh3Dm3Fy5hV96RBQ5uiUpuGYGcNX8nLiqi-uKvCb39HOhgFWAf45jl_I9bxE4DbFZYT8KDNFXTtNkV-v1FnrEtzIMXDWaFnhdq7qeI2LY86duSUzaz1CsJGKfsb03kx_nRvtuMsjp8ngY1XwUVN3_mDdDsYyzaYIQWBhdWciqAvx9_ZQRZwFvDI2qpkZ_AfGkt7Af-iywV962vNi5xGGB4Y6x90d_1JuaJSHDNeieDQ",
//         name: "Dev Mock",
//         routepath: "/backoffice",
//         adminpath: "admin/index.html",
//         role: "BACK_OFFICE",
//         thumb: "https://lh5.googleusercontent.com/-StfBWE2LrnA/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3remKlgr7dC58E3vJsN6SwyuN2-gnA/s96-c/photo.jpg"
//     });
// }
// export const getRole = () => "BACK_OFFICE";